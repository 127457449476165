.dsgvo-container {
  max-width: 100vw;
  padding: 20px;
  margin: auto;

  @media (min-width: 500px) {
    max-width: 1000px;
    padding: 40px;
  }
}
